import { useEffect, useRef } from 'react';
import { configuration } from '@environments/env';
import { useAuth } from '@providers/AuthProvider';

interface IMarkupViewerProps {
  documentId: string;
  jwt: string | null;
  title?: string | null;
  onClose?: () => void;
}

export const MarkupViewer = (props: IMarkupViewerProps) => {
  const { documentId, jwt, title, onClose = undefined } = props;
  const containerRef = useRef(null);

  const { user } = useAuth();

  useEffect(() => {
    const container = containerRef.current; // This `useRef` instance will render the PDF.

    let PSPDFKit, instance;

    (async function () {
      PSPDFKit = await import('pspdfkit');

      PSPDFKit.unload(container); // Ensure that there's only one PSPDFKit instance.

      //   const defaultItems = PSPDFKit.defaultToolbarItems;
      //   console.log(defaultItems);
      // PSPDFKit exposes the default list of items as PSPDFKit.defaultToolbarItems
      // This is an Array that can be filtered or customized as you please.
      const toolbarItems = PSPDFKit.defaultToolbarItems.filter(item => {
        return /\b(sidebar-thumbnails|sidebar-annotations|pager|multi-annotations-selection|pan|zoom-in|zoom-out|zoom-mode|spacer|text|line|arrow|rectangle|ellipse|polygon|cloudy-rectangle|polyline|export-pdf)\b/.test(
          item.type
        );
      });

      if (title) {
        toolbarItems.unshift({
          type: 'custom',
          id: 'filename-markup',
          title: title,
        });
      }

      if (onClose) {
        toolbarItems.push({
          type: 'custom',
          id: 'close-markup',
          title: 'Close',
          icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>',
          onPress: onClose,
        });
      }

      instance = await PSPDFKit.load({
        // Use the public directory URL as a base URL. PSPDFKit will download its library assets from here.
        baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,

        //Configuration
        serverUrl: configuration.DOCUMENT_ENGINE_URL,
        instant: false,
        autoSaveMode: PSPDFKit.AutoSaveMode.INTELLIGENT,
        enableHistory: true,
        authPayload: { jwt: jwt },
        container,
        documentId: documentId,

        //customization
        toolbarItems: toolbarItems,
        theme: PSPDFKit.Theme.DARK,
        locale: 'en', //pass along the user setting here. Otherwise default to 'en'
      });

      if (user) {
        instance.setAnnotationCreatorName(user.firstName + ' ' + user.lastName + ' (' + user.email + ')');
      }
    })();

    return () => PSPDFKit && PSPDFKit.unload(container);
  }, [documentId, jwt, title, onClose, user]);

  if (configuration.DOCUMENT_ENGINE_URL === undefined) {
    return null;
  }

  // This div element will render the document to the DOM.
  return <div ref={containerRef} style={{ width: '100%', height: '100vh' }} />;
};
