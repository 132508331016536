import { Badge, InputRef, theme } from "antd";
import "@styles/components/admin-search-results.less";
import { SearchTable } from "@components/Utils/SearchTable";
import { useNavigate } from "react-router-dom";
import { RefObject, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFeatureFlagsProvider } from "@providers/FeatureFlagsProvider";
import { FeatureFlagOptionEnum } from "@models/enumerations/feature-flag-option.enum";

interface IAdminSearchResult {
    path: string
    name: string
    actionCards: string[]
}

interface IAdminSearchResultProps {
    adminTab: IAdminSearchResult
    searchStr: string
}

const getHighlightedText = (searchString: string, targetString: string): string => {
    // Check if the targetString contains the searchString
    if (targetString.toLowerCase().includes(searchString.toLowerCase())) {
        // Replace all occurrences of the searchString with a strong block
        const highlightedString = targetString.replace(
            new RegExp(searchString, 'gi'),
            (match) => `<strong>${match}</strong>`
        );
        return highlightedString;
    } else {
        // If no match found, return the original targetString
        return targetString;
    }
}

const AdminSearchResult = (props: IAdminSearchResultProps) => {
    const { adminTab, searchStr } = props;
    const { token: { colorFillSecondary, colorTextTertiary } } = theme.useToken();
    const navigate = useNavigate();

    const onClick = () => {
        navigate(`/admin/${adminTab.path}`)
    }

    return <div className="admin-search-result" onClick={onClick}>
        <div dangerouslySetInnerHTML={{__html: getHighlightedText(searchStr, adminTab.name)}} />
        <div>
            {adminTab.actionCards.map(c => {
                return <Badge count={<span className="action-card-name" dangerouslySetInnerHTML={{__html: getHighlightedText(searchStr, c)}} />} style={{ backgroundColor: colorFillSecondary, color: colorTextTertiary }}/>
            })}
        </div>
    </div>
}

export const AdminSearch = () => {

    const { t } = useTranslation();
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const { flags, isFlagEnabled } = useFeatureFlagsProvider();
    const isRFIEnabled = isFlagEnabled(FeatureFlagOptionEnum.RFI);
    const isBudgetEnabled = isFlagEnabled(FeatureFlagOptionEnum.BUDGET);
    const isOxBlueEnabled = isFlagEnabled(FeatureFlagOptionEnum.OX_BLUE_INTEGRATION);
    const isChangeOrdersEnabled = isFlagEnabled(FeatureFlagOptionEnum.CHANGE_ORDERS);

    const adminTabs: IAdminSearchResult[] = useMemo(() => {
        const tabs = [
            {
                path: 'access-and-permissions/users',
                name: `${t("admin.accessAndPermissions")} / ${t("admin.users")}`,
                actionCards: [
                    t("admin.users")
                ]
            },
            {
                path: 'access-and-permissions/groups',
                name: `${t("admin.accessAndPermissions")} / ${t("admin.groups")}`,
                actionCards: [
                    t("admin.groups")
                ]
            },
            {
                path: 'access-and-permissions/permissions',
                name: `${t("admin.accessAndPermissions")} / ${t("admin.permissions")}`,
                actionCards: [
                    t("admin.permissions")
                ]
            },
            {
                path: 'application-configuration/cost-management',
                name: `${t("admin.applicationConfiguration")} / ${t("admin.costManagement")}`,
                actionCards: [
                    t("admin.defaultCurrency")
                ]
            },
            {
                path: 'application-configuration/cost-management/cost-groups',
                name: `${t("admin.applicationConfiguration")} / ${t("admin.costManagement")} / ${t("admin.costGroups")}`,
                actionCards: [
                    t("admin.costGrouping"),
                    t("admin.costCategories"),
                    t("admin.costSubcategories")
                ]
            },
            {
                path: 'application-configuration/cost-management/available-currencies',
                name: `${t("admin.applicationConfiguration")} / ${t("admin.costManagement")} / ${t("admin.availableCurrencies")}`,
                actionCards: [
                    t("admin.availableCurrencies")
                ]
            },
            {
                path: 'application-configuration/other-settings',
                name: `${t("admin.applicationConfiguration")} / ${t("admin.otherSettings")}`,
                actionCards: [
                    t("admin.discipline"),
                    t("admin.role"),
                    t("admin.companyRole"),
                    t("admin.addressType"),
                    t("admin.market"),
                    t("admin.brand")
                ]
            },
            {
                path: 'application-configuration/custom-fields',
                name: `${t("admin.applicationConfiguration")} / ${t("admin.customFields")}`,
                actionCards: [
                    t("admin.customFields")
                ]
            },
            {
                path: 'application-configuration/integration',
                name: `${t("admin.applicationConfiguration")} / ${t("admin.integration")}`,
                actionCards: [
                    "OxBlue"
                ]
            },
            {
                path: 'module-configuration/sites',
                name: `${t("admin.moduleConfiguration")} / ${t("admin.sites")}`,
                actionCards: [
                    t("admin.status"),
                ]
            },
            {
                path: 'module-configuration/projects',
                name: `${t("admin.moduleConfiguration")} / ${t("admin.projects")}`,
                actionCards: [
                    t("admin.status"),
                    t("admin.type")
                ]
            },
            {
                path: 'module-configuration/project-deliverables',
                name: `${t("admin.moduleConfiguration")} / ${t("admin.projectDeliverables")}`,
                actionCards: [
                    t("admin.phase"),
                    t("admin.changeReasons"),
                    t("admin.changeReasonOptions")
                ]
            },
            {
                path: 'module-configuration/project-deliverables/deliverables',
                name: `${t("admin.moduleConfiguration")} / ${t("admin.projectDeliverables")} / ${t("admin.deliverables")}`,
                actionCards: [
                    t("admin.deliverables")
                ]
            },
            {
                path: 'module-configuration/project-deliverables/templates',
                name: `${t("admin.moduleConfiguration")} / ${t("admin.projectDeliverables")} / ${t("admin.templates")}`,
                actionCards: [
                    t("admin.templates")
                ]
            },
            {
                path: 'file-preview-utils',
                name: t("admin.filePreviewGeneration"),
                actionCards: [
                    t("admin.filePreviewGeneration")
                ]
            },
            {
                path: 'module-configuration/rfi',
                name: `${t("admin.moduleConfiguration")} / ${t("admin.rfi")}`,
                actionCards: [
                    t("admin.rfiCategory"),
                    t("admin.rfiReason")
                ]
            },
            {
                path: 'module-configuration/change-order',
                name: `${t("admin.moduleConfiguration")} / ${t("admin.changeOrder")}`,
                actionCards: [
                    t("admin.changeOrderReason"),
                    t("admin.changeOrderRejectionReason")
                ]
            },
            {
                path: 'module-configuration/budget',
                name: `${t("admin.moduleConfiguration")} / ${t("budget.budget")}`,
                actionCards: [
                    t("admin.budgetTemplates"),
                    t("admin.approvalRouting")
                ]
            },
            {
                path: 'module-configuration/budget/templates',
                name: `${t("admin.moduleConfiguration")} / ${t("budget.budget")} / ${t("admin.budgetTemplates")}`,
                actionCards: [
                    t("admin.budgetTemplates")
                ]
            },
            {
                path: 'module-configuration/punch-list',
                name: `${t("admin.moduleConfiguration")} / ${t("punchList.punchList")}`,
                actionCards: [
                    t("admin.category"),
                    t("admin.status"),
                    t("admin.reason")
                ]
            }];
            if (!isChangeOrdersEnabled) {
                const idx = tabs.findIndex(x => x.path === 'module-configuration/change-order');
                tabs.splice(idx, 1);
            }
            if (!isRFIEnabled) {
                const idx = tabs.findIndex(x => x.path === 'module-configuration/rfi');
                tabs.splice(idx, 1);
            }
            if (!isBudgetEnabled) {
                const idx = tabs.findIndex(x => x.path === 'module-configuration/budget');
                tabs.splice(idx, 1);
                const idx2 = tabs.findIndex(x => x.path === 'module-configuration/budget/templates');
                tabs.splice(idx2, 1);
            }
            if (!isOxBlueEnabled) {
                const idx = tabs.findIndex(x => x.path === 'application-configuration/integration');
                tabs.splice(idx, 1);
            }
            return tabs;
        /* eslint-disable react-hooks/exhaustive-deps */
        }, [isRFIEnabled, isBudgetEnabled]);

    let searchRef: RefObject<InputRef>;
    useEffect(() => {
        searchRef?.current?.focus();
        /* eslint-disable react-hooks/exhaustive-deps */
    }, []);

    const [search, setSearch] = useState<string>('')

    const filteredTabs = useMemo(() => {
        if (!search) {
            return []
        }
        return adminTabs.filter(x => 
            x.name.toLowerCase().includes(search.toLowerCase()) || 
            x.actionCards.some(c => c.toLowerCase().includes(search.toLowerCase())))
        /* eslint-disable react-hooks/exhaustive-deps */
    }, [search])

    return <>
        <SearchTable 
            onInitRef={(ref: RefObject<InputRef>) => { searchRef = ref; }}
            className="mt-10 mb-10" 
            style={{ width: '100%' }} 
            placeholder={`${t("admin.searchAdminOptions")}`}
            handleOnSearch={() => { }}
            onChange={(e) => { setSearch(e.currentTarget.value) }}
            onClear={() => { setSearch('') }} />
        {filteredTabs.map((at) => {
            return <AdminSearchResult key={at.path} searchStr={search} adminTab={at} />
        })}
    </>
}